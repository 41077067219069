import moment from 'moment'
import { useState, useEffect } from 'react'
import * as service from '../energilityServices/checkViewService/services/checkHeader.service'
export const useCheckHeader = (selection: any, page: any, limitPages: any, setCurrentPage: any) => {
  const setTimeInitial = 300;
    const [checkHeader, setcheckHeader] = useState({
      listItems: [] as any
    });
    const [totalPage, setTotalPage] = useState(0);
    

    useEffect(() => {
      if (page > 0) {
        const timeout = setTimeout(() => {
          getCheckHeader();
        }, setTimeInitial)
        return () => clearTimeout(timeout)
      }
    }, [page, selection, limitPages]);

    useEffect(() => {
      if (selection.fromDate !== '' && selection.toDate !== '') {
        setCurrentPage(1);
      }
    }, [selection.filters, selection.fromDate, selection.toDate]);
  
    const getCheckHeader = async() => {
        const response = await service.getCheckHeaderView(selection, page, limitPages);
        if(response !== undefined){
          setTotalPage(response.totalPages)
        const listUpdate = response.items.map((item: any) => {
          let itemParsed = moment(item.checkDate);
          let itemDate = itemParsed.format("MM/DD/YYYY");
          return ({
              id: item.checkHeaderId,
              docSystemNo: item.docSystemNo,
              checkDate: itemDate,
              checkNumber: item.checkNumber,
              ownerNumber: item.ownerNumber,
              ownerName: item.ownerName,
              grossAmount: item.grossAmount,
              taxs: item.taxs,
              deductions: item.deductions,
              totalAmount: item.totalAmount
          })

        });
        setcheckHeader({
          ...checkHeader,
          listItems: listUpdate
        });
        }   
    }

    return {
      checkHeader, 
      totalPage
    }

}