import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Paper, FormControl, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableRow, Checkbox } from '@mui/material';
import { useCheckHeader } from '../../hooks/useCheckHeader';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faUpRightFromSquare} from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';
import Pagination from '@mui/material/Pagination';
interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount} = props;
  return (
    <thead className="fm_table--head">
      <TableRow sx={{ '& > *': { borderBottom: 'none' } }}>
        <TableCell className="fm_tb_name col-1">
            <Checkbox
                className='fm-white-icon'
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                'aria-label': 'select all desserts',
                }}
            />
        </TableCell>
        <TableCell className="fm_tb_name col-1">Check Date</TableCell>
        <TableCell className="fm_tb_name col-1">Check Number</TableCell>
        <TableCell className="fm_tb_name col-1">Owner Number</TableCell>
        <TableCell className="fm_tb_name col-3">Owner Name</TableCell>
        <TableCell className="fm_tb_name col-1 text-end">Gross Amount</TableCell>
        <TableCell className="fm_tb_name col-1 text-end">Tax</TableCell>
        <TableCell className="fm_tb_name col-1 text-end">Deductions</TableCell>
        <TableCell className="fm_tb_name col-1 text-end">Total Amount</TableCell>
        <TableCell className="fm_tb_name col-1">Actions</TableCell>
      </TableRow>
    </thead>
  );
}

const CheckHeaderComponent = (props: any) => {
    const { selection, companyName, setLimitPages, limitPages, setSelected, selected, maintainState } = props;
    const cookies = new Cookies();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [showDetails, setShowDetails] = useState(false);
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
        const newSelecteds = checkHeader.listItems.map((n: any) => n.docSystemNo);
        setSelected(newSelecteds);
        return;
        }
        setSelected([]);
    }
    
    const handleClick = (event: React.MouseEvent<unknown>, docSystemNo: string) => {
        const selectedIndex = selected.indexOf(docSystemNo);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, docSystemNo);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
        }

        setSelected(newSelected);
    }

    const isSelected = (docSystemNo: string) => selected.indexOf(docSystemNo) !== -1;
    
    const handleChange = (event: any) => {
        setLimitPages(event.target.value);
        setCurrentPage(1);
    }    
    
    const handleCurrentPage = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    }

    const numberFormat = function (number: number) {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(number);
    }
    
    const onViewPdf = (docSystemNo: number) => {
        cookies.set('fromDate', maintainState.fromDate, { path: '/' });
        cookies.set('toDate', maintainState.toDate, { path: '/' });
        cookies.set('filters', maintainState.filters, { path: '/' });
        navigate(`/CheckDetails/${selection.companyId}/${selection.docTypeId}/${companyName}/${docSystemNo}`);
    }

    const onDownloadPdf = (docSystemNo: number) => {
        window.open(`/ViewPDF/${docSystemNo}/2`, '_blank');
    }

    const { checkHeader, totalPage } = useCheckHeader(selection, currentPage, limitPages, setCurrentPage);

    useEffect(() => {
        if (checkHeader.listItems.length !== 0) {
            routerIndex();
        }
    }, [checkHeader.listItems, currentPage]);

    function routerIndex() {
        setShowDetails(true);
        setSelected([]);
    }

    return (
        <CardContent>
            <TableContainer component={Paper}>
            <Table aria-label="collapsible table" className="fm_table" size="small">
                <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={checkHeader.listItems.length}
                />
                    <TableBody>
                            {checkHeader.listItems.map((row: any, index: any) => {
                                const isItemSelected = isSelected(row.docSystemNo);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                <TableRow key={index}>
                                    <TableCell className="col-1">
									<Checkbox
                                        className='fm-pcolor-icon'
									    checked={isItemSelected}
									    inputProps={{
    										'aria-labelledby': labelId,
									    }}
									    onChange={(event: any, data: any) => handleClick(event, row.docSystemNo)}
									/>
                                    </TableCell> 
                                    <TableCell className="fm_cell_h fm_tb_cell">{row.checkDate}</TableCell>
                                    <TableCell className="fm_cell_h fm_tb_cell">{row.checkNumber}</TableCell>
                                    <TableCell className="fm_cell_h fm_tb_cell">{row.ownerNumber}</TableCell>
                                    <TableCell className="fm_cell_h fm_tb_cell">{row.ownerName}</TableCell>
                                    <TableCell className="fm_cell_h fm_tb_cell text-end">{numberFormat(row.grossAmount)}</TableCell>
                                    <TableCell className="fm_cell_h fm_tb_cell text-end">{numberFormat(row.taxs)}</TableCell>
                                    <TableCell className="fm_cell_h fm_tb_cell text-end">{numberFormat(row.deductions)}</TableCell>
                                    <TableCell className="fm_cell_h fm_tb_cell text-end">{numberFormat(row.totalAmount)}</TableCell>
                                    <TableCell>
                                        <div className="fm_table--actions d-flex align-items-center">
                                            <button className="fa-xl fm-button--link--icon tooltip" onClick={() => onDownloadPdf(row.docSystemNo)}>
                                                <FontAwesomeIcon icon={faFilePdf} />
                                                <span className="tooltiptext">View PDF</span>
                                            </button>
                                            <button className="fa-xl fm-button--link--icon tooltip" onClick={() => onViewPdf(row.docSystemNo)}>
                                                <FontAwesomeIcon icon={faUpRightFromSquare} />
                                                <span className="tooltiptext">Check Detail</span>
                                            </button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
                </TableContainer>
                <div className="row justify-content-end fm_table--pagination">
                        <div className="col-md-auto col-sm-12 justify-content-center">
                                <div className="d-flex w-100 align-items-center justify-content-center fm_table--pagination--rows">
                                    <p className="fm_paragraph">Rows per page</p>
                                    <FormControl className="d-flex">
                                        <Select className="fm_table--pagination--rows--input" labelId="demo-simple-select-label" id="demo-simple-select" value={limitPages} onChange={handleChange}>
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                            <MenuItem value={100}>100</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                        </div>
                        <div className="col-md-auto col-sm-12 align-self-center justify-content-center">
                                <div className="d-flex w-100 align-items-center justify-content-center">
                                    {showDetails && (
                                        <Pagination count={totalPage} page={currentPage} 
                                        onChange={handleCurrentPage} showFirstButton showLastButton color="primary"/>
                                    )}
                                </div>
                        </div>
                </div>
        </CardContent>
    );
}

export default CheckHeaderComponent;

const CardContent = styled.div``;