import { useEffect } from 'react';
import { useDashboard } from '../hooks';
import { useNavigate } from 'react-router-dom';
import CheckDollarIcon from '../assets/icons/money-check-dollar.svg';
import FmTitle from './MainTitle';
import Cookies from 'universal-cookie';

const DashboardComponent = () => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const { listAllDashboard } = useDashboard();
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    function redirectCheckView(itemCompanyId: number, itemDocTypeId: number, itemcompanyName: string) {
        navigate(`/CheckView/${itemCompanyId}/${itemDocTypeId}/${itemcompanyName}`);
    }

    useEffect(() => {
        cookies.remove("fromDate", { path: "/" });
        cookies.remove("toDate", { path: "/" });
        cookies.remove("filters", { path: "/" });
    });
    

    return (
        <div>
            <FmTitle title="Dashboard" backPage={false} />
            <div className="row g-3">
                {listAllDashboard.listDashboards.map((key: any, index: any) => {
                    return (
                        <div className="col-xl-3 col-md-6 col-sm-12" key={index} onClick={() => redirectCheckView(key.id, key.docTypeId, key.senderName)}>
                            <div className="d-flex flex-column flex-lg-wrap fm_homecard fm_card ">
                                <div className="d-flex fm_homecard--head align-items-center">
                                    <div className="fm_homecard--icon">
                                        <img src={CheckDollarIcon} alt="" />
                                    </div>
                                    <div className="d-flex flex-column">
                                        <p className="fm_homecard--font--18">{key.senderName}</p>
                                        <p className="fm_homecard--font--14">{key.docTypeDescription}</p>
                                        <p className="fm_homecard--font--14">{key.firstDay}</p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-end">
                                    <div className="col-auto">
                                        <p className="fm_homecard--font--14">Total Amount</p>
                                        <h1 className="fm_homecard--font--34">{formatter.format(key.totalAmount)}</h1>
                                    </div>
                                    <div className="fm_homecard--nChecks">
                                        <p className="fm_homecard--font--16">{key.totalChecks}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DashboardComponent;
