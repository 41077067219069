import { Text, View, StyleSheet } from "@react-pdf/renderer";
import styles from "../pdfRender/PDFStyles";

const numberFormat = function (number: number) {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(number);
};

const PDFTaxes = ( taxes: any ) => {
    if (taxes.taxes.length > 0) {
        return (
            <View >
                {taxes.taxes.map((tax: any, index: any) => {
                    return(
                        <View style={styles.sctSubTableInfo} wrap={false} key={index}>
                            <View style={styles.ColSubTableInfoDedTaxTxt8}>
                                <Text></Text>
                            </View>
                            <View style={styles.ColSubTableInfoDedTaxTxt5}>
                                <Text></Text>
                            </View>
                            <View style={styles.ColSubTableInfoDedTaxTxt13}>
                                <Text></Text>
                            </View>
                            <View style={styles.ColSubTableDedTaxDesc}>
                                <Text>{tax.taxTypeDesc}</Text>
                            </View>
                            <View style={styles.ColSubTableInfoDedTaxNo9}>
                                <Text></Text>
                            </View>
                            <View style={styles.ColSubTableInfoDedTaxNo9}>
                                <Text>{numberFormat(tax.grossTaxValue)}</Text>
                            </View>
                            <View style={styles.ColSubTableInfoDedTaxNo9}>
                                <Text></Text>
                            </View>
                            <View style={styles.ColSubTableInfoDedTaxNo7}>
                                <Text></Text>
                            </View>
                            <View style={styles.ColSubTableInfoDedTaxNo9}>
                                <Text></Text>
                            </View>
                            <View style={styles.ColSubTableInfoDedTaxNo9}>
                                <Text>{numberFormat(tax.ownerTaxValue)}</Text>
                            </View>
                        </View>
                    )
                })}
            </View>
        );
    } else {
        return (<View ></View>);
    }
    
};

export default PDFTaxes;