import { Document, Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import conocoImage from "../../assets/img/CrawfordLogo.png"
import PDFProperties from "../pdfRender/PdfProperties";
import moment from 'moment';
import styles from "../pdfRender/PDFStyles";

const numberFormat = function (number: number) {
   return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(number);
};
const volumeFormat = function (number: number) {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3, }).format(number);
};

const DocuPDF = ( docSystemNoParent: any ) => {
    return (
      <Document>
        {docSystemNoParent.docSystemNoParent.length === 0 && <div></div>}
        {docSystemNoParent.docSystemNoParent.length > 0 && docSystemNoParent.docSystemNoParent.map((key:any, index: any) => {
          return (
            <Page size="A4" orientation='landscape' style={styles.page} wrap key={index}>
              <View style={styles.sectionTop}>
                <View style={styles.sectionImage}>
                  <Image src={conocoImage} style={styles.imageStyle} />
                </View>
                <View style={styles.stnCompanyName}>
                  <Text>{key.companyName}</Text>
                </View>
              </View>
              <View style={styles.section}>
                <View style={styles.ColumnText}>
                  <Text>Check Number:</Text>
                </View>
                <View style={styles.sectionWidth35}>
                  <Text>{key.documentNo}</Text>
                </View>
                <View style={styles.sectionWidth30}></View>
                <View style={styles.ColumnText}>
                  <Text>Gross:</Text>
                </View>
                <View style={styles.ColumnNumberRight}>
                  <Text>{numberFormat(key.grossValue)}</Text>
                </View>
              </View>
              <View style={styles.section}>
                <View style={styles.ColumnText}>
                  <Text>Check Date:</Text>
                </View>
                <View style={styles.sectionWidth35}>
                  <Text>{moment(key.documentDate).format('MM/DD/YYYY')}</Text>
                </View>
                <View style={styles.sectionWidth30}></View>
                <View style={styles.ColumnText}>
                  <Text>Taxes:</Text>
                </View>
                <View style={styles.ColumnNumberRight}>
                  <Text>{numberFormat(key.taxValue)}</Text>
                </View>
              </View>
              <View style={styles.section}>
                <View style={styles.ColumnText}>
                  <Text>Check Type:</Text>
                </View>
                <View style={styles.sectionWidth35}>
                  <Text>{key.docTypeDescription}</Text>
                </View>
                <View style={styles.sectionWidth30}></View>
                <View style={styles.ColumnText}>
                  <Text>Deductions:</Text>
                </View>
                <View style={styles.ColumnNumberRight}>
                  <Text>{numberFormat(key.deductionValue)}</Text>
                </View>
              </View>
              <View style={styles.section}>
                <View style={styles.ColumnText}>
                  <Text>Owner Number:</Text>
                </View>
                <View style={styles.sectionWidth35}>
                  <Text>{key.businessPartnerNo}</Text>
                </View>
                <View style={styles.sectionWidth30}></View>
                <View style={styles.ColumnText}>
                  <Text>Check Amount:</Text>
                </View>
                <View style={styles.ColumnNumberRight}>
                  <Text>{numberFormat(key.netValue)}</Text>
                </View>
              </View>
              <View style={styles.section}>
                <View style={styles.ColumnText}>
                  <Text>Owner Name:</Text>
                </View>
                <View style={styles.sectionWidth35}>
                  <Text>{key.bpName}</Text>
                  <Text>{key.bpName2}</Text>
                  <Text>{key.bpName3}</Text>
                  <Text>{key.bpName4}</Text>
                  <Text>{key.bpName5}</Text>
                </View>
                <View style={styles.sectionWidth30}></View>
                <View style={styles.ColumnText}>
                  <Text>Check Address:</Text>
                </View>
                <View style={styles.ColumnAddressLeft}>
                  <Text>{key.bpAddress}</Text>
                  <Text>{key.bpAddress2}</Text>
                  <Text>{key.bpAddress3}</Text>
                  <Text>{key.bpAddress4}</Text>
                  <Text>{key.bpAddress5}</Text>
                </View>
              </View>
              {key.chHdrDetails.map((dtl: any, jindex: any) => {
                return (
                  <View key={jindex}>
                    <View style={styles.sectUnderlineBottom} wrap={false}>
                      <View style={styles.ColumnHeaderProperty20}>
                        <Text>Property No</Text>
                      </View>
                      <View style={styles.ColumnHeaderProperty20}>
                        <Text>Property Name</Text>
                      </View>
                      <View style={styles.ColumnHeaderProperty15}>
                        <Text>State</Text>
                      </View>
                      <View style={styles.ColumnHeaderProperty15}>
                        <Text>County</Text>
                      </View>
                      <View style={styles.ColumnHeaderProperty10}>
                        <Text>Product</Text>
                      </View>
                      <View style={styles.ColumnHeaderProperty20}>
                        <Text>Product Name</Text>
                      </View>
                    </View>
                    <View style={styles.sectionPropertyHeader} wrap={false}>
                      <View style={styles.ColumnHeaderProperty20}>
                        <Text>{dtl.propertyNo}</Text>
                      </View>
                      <View style={styles.ColumnHeaderProperty20}>
                        <Text>{dtl.propertyName1}</Text>
                      </View>
                      <View style={styles.ColumnHeaderProperty15}>
                        <Text>{dtl.stateName}</Text>
                      </View>
                      <View style={styles.ColumnHeaderProperty15}>
                        <Text>{dtl.countyName}</Text>
                      </View>
                      <View style={styles.ColumnHeaderProperty10}>
                        <Text>{dtl.productCode}</Text>
                      </View>
                      <View style={styles.ColumnHeaderProperty20}>
                        <Text>{dtl.productName}</Text>
                      </View>
                    </View>
                    <PDFProperties properties={dtl}></PDFProperties>
                    <View style={styles.sctSubTableNetValue} wrap={false}>
                      <View style={styles.ColSubTableInfoTxt13}>
                        <Text>{dtl.propertyNo}</Text>
                      </View>
                      <View style={styles.ColSubTableInfoTxt41}>
                        <Text>{dtl.propertyName1}</Text>
                      </View>
                      <View style={styles.ColSubTableInfoTxt16}>
                        <Text>NET VALUE:</Text>
                      </View>
                      <View style={styles.ColSubTableInfoNo7}>
                        <Text></Text>
                      </View>
                      <View style={styles.ColSubTableInfoTxt18}>
                        <Text></Text>
                      </View>
                      <View style={styles.ColSubTableInfoNo9}>
                        <Text>{volumeFormat(dtl.checkDetailTotalVolume)}</Text>
                      </View>
                      <View style={styles.ColSubTableInfoNo9}>
                        <Text>{numberFormat(dtl.checkDetailTotalValue)}</Text>
                      </View>
                    </View>
                  </View>    
                )
              })}
              <View wrap={true}>
                <View style={styles.sectionForTotals} wrap={true} >
                  <View style={styles.stnWidth48}></View>
                  <View style={styles.stnHdrTotal}>
                    <Text>TOTALS FOR ALL PRODUCTS</Text>
                  </View>
                </View>
                <View style={styles.sctnTotals} wrap={true}>
                  <View style={styles.stnWidth48}></View>
                  <View style={styles.stnInfoTotal}>
                    <View style={styles.stnTextTotals}>
                        <Text>TOTAL GROSS AMOUNT:</Text>
                      </View>
                      <View style={styles.stnNumberTotals}>
                        <Text>{numberFormat(key.grossValue)}</Text>
                      </View>
                  </View>
                </View>
                <View style={styles.sctnTotals} wrap={true}>
                  <View style={styles.stnWidth48}></View>
                  <View style={styles.stnInfoTotal}>
                    <View style={styles.stnTextTotals}>
                        <Text>TOTAL AMOUNT BEFORE TAXES:</Text>
                      </View>
                      <View style={styles.stnNumberTotals}>
                        <Text>{numberFormat(key.grossValue - key.deductionValue)}</Text>
                      </View>
                  </View>
                </View>
                <View style={styles.sctnTotals} wrap={true}>
                  <View style={styles.stnWidth48}></View>
                  <View style={styles.stnInfoTotal}>
                    <View style={styles.stnTextTotals}>
                        <Text>TAXES:</Text>
                      </View>
                      <View style={styles.stnNumberTotals}>
                        <Text>{numberFormat(key.taxValue)}</Text>
                      </View>
                  </View>
                </View>
                <View style={styles.sctnTotals} wrap={true}>
                  <View style={styles.stnWidth48}></View>
                  <View style={styles.stnInfoTotal}>
                    <View style={styles.stnTextTotals}>
                        <Text>BACKUP WITHHOLDING:</Text>
                      </View>
                      <View style={styles.stnNumberTotals}>
                        <Text>{numberFormat(key.whldngVal)}</Text>
                      </View>
                  </View>
                </View>
                <View style={styles.sctnTotals} wrap={true}>
                  <View style={styles.stnWidth48}></View>
                  <View style={styles.stnInfoTotal}>
                    <View style={styles.stnTextTotals}>
                        <Text>NET AMOUNT PAID:</Text>
                      </View>
                      <View style={styles.stnNumberTotals}>
                        <Text>{numberFormat(key.netValue)}</Text>
                      </View>
                  </View>
                </View>
              </View>
              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `Page ${pageNumber} of ${totalPages}`
              )} fixed />
              <View style={styles.pageNumbers} render={({ pageNumber }) => (
                pageNumber % 1 === 0 && (
                  <View style={styles.stcFooter}>
                    <Text style={styles.footerStyles}>For questions and resources, please visit our website: www.ConocoPhillips.com/US-Interest-Owners</Text>
                    <Text style={styles.footerStyles}>Or you can write to: {key.companyAddress}</Text>
                    <Text style={styles.footerStyles}>When inquiring, please refer to your owner number.</Text>
                    <Text style={styles.footerStyles}>To access your payment detail or 1099s online, visit www.EnergyLink.com. If you have not registered before, follow the prompts for new users. </Text>
                  </View>
                )
              )} fixed/>
            </Page>
          );
        })}
      </Document>
    );
};

export default DocuPDF;
