import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  AlertTitle,
  Alert,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as service from "../../energilityServices/userProfile/services/user.service";
import Cookies from "universal-cookie";

const RecoverPassComponent = (props: any) => {
  const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/Login`;
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [useEmail, setEmail] = useState({
    email: "",
  });
  const [alertTitle, setAlertTitle] = useState(false);
  const [alertEmail, setAlertEmail] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [validateScreen, setValidateScreen] = useState(false);

  useEffect(() => {
    var validateForgot = cookies.get("forgotPassword");
    if (cookies.get("userId") && validateForgot === "true") {
      navigate("/NewPassword", { replace: true });
      setValidateScreen(false);
    } else {
      if (cookies.get("userId")) {
        navigate("/Dashboard", { replace: true });
        setValidateScreen(false);
      } else {
        setValidateScreen(true);
      }
    }
  });

  const handleKeywordKeyPress = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.key === "Enter") {
      recoverPass();
    }
  };

  const recoverPass = async () => {
    if (useEmail.email !== "") {
      const validateEmail = await axios.get(
        baseUrl + `/ValidateEmail?email=${useEmail.email}`
      );
      if (validateEmail.data.response === true) {
        setAlertEmail(false);
        setAlertTitle(false);
        await service.postRecoverPassword(useEmail.email).then((response) => {
          setAlertSuccess(true);
        });
      } else {
        setAlertEmail(true);
        setAlertTitle(false);
      }
    } else {
      setAlertTitle(true);
      setAlertEmail(false);
    }
  };

  return (
    <div className="container">
      {validateScreen === true && (
        <Box className="d-flex flex-column align-items-center justify-content-center fm_login_container">
          <div className="fm_card fm_rp d-flex flex-column ">
            <h1 className="logincard__form__title text-center">
              Recover Password
            </h1>
            {!alertSuccess && (
              <p className="text-center">
                Please, enter the email address associated with your account and
                we will email you a new password.
              </p>
            )}
            {alertSuccess && (
              <Alert severity="success">
                We've sent you an email with your temporary password.
              </Alert>
            )}
            {!alertSuccess && (
              <div className="fm_rp--gap d-flex flex-column">
                <TextField
                  fullWidth
                  className="fm_input"
                  label="Email Address"
                  name="email"
                  type="email"
                  size="small"
                  variant="outlined"
                  onChange={(event) =>
                    setEmail({ ...useEmail, email: event.target.value })
                  }
                  inputProps={{
                    maxLength: 100,
                  }}
                  onKeyPress={handleKeywordKeyPress}
                />
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  className="fm-button--colored"
                  color="primary"
                  onClick={() => recoverPass()}
                >
                  Reset password
                </Button>
              </div>
            )}

            <p className="text-center">
              Back to login?{" "}
              <Link className="fm-button--link" to={"/"}>
                Sign in
              </Link>
            </p>

            {alertTitle && (
              <Alert severity="error" className="fm_rp--alert">
                <AlertTitle>
                  Please, verify that the field is filled.
                </AlertTitle>
              </Alert>
            )}
            {alertEmail && (
              <Alert severity="error" className="fm_rp--alert">
                <AlertTitle>The email is incorrect.</AlertTitle>
              </Alert>
            )}
          </div>
        </Box>
      )}
    </div>
  );
};

export default RecoverPassComponent;
