import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

const LoadingComponent = () => {
  return (
    <Backdrop
      className="loader"
      open={true}
      sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
    >
      <CircularProgress/>
    </Backdrop>
  );
};

export default LoadingComponent;
