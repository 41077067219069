import { useEffect } from 'react'
import { alpha, styled } from '@mui/material/styles'
import { AppBar } from '@mui/material'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser } from '@fortawesome/free-solid-svg-icons'

export default function DashboardNavbarComponent() {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const profileName = `${cookies.get("firstName")} ${cookies.get("lastName")}`;
    const setTimeInitial = 3000 * 60 * 60;

    const logOut = () => {
        cookies.remove("userId", { path: "/" });
        cookies.remove("userName", { path: "/" });
        cookies.remove("firstName", { path: "/" });
        cookies.remove("lastName", { path: "/" });
        cookies.remove("email", { path: "/" });
        cookies.remove("fromDate", { path: "/" });
        cookies.remove("toDate", { path: "/" });
        cookies.remove("filters", { path: "/" });
        cookies.remove("dataPointIndex", { path: "/" });
        navigate("/", { replace: true });
      };

    useEffect(() => {
        const timeout = setTimeout(() => {
            logOut()
        }, setTimeInitial)

        return () => clearTimeout(timeout)
    }, [])

    return (
        <RootStyle>
            <div className="container fm_main_container--topheader ">
                <div className="row h-100 align-items-center justify-content-between">
                    <div className="col-auto">
                        <div className="fm-logo"></div>
                    </div>
                    <div className="col-auto">
                        <div
                            className="d-flex align-items-center justify-content-end fm_th_btn fm-button--link--it"
                            onClick={() => logOut()}
                        >
                            <div className="fa-xl">
                                <FontAwesomeIcon icon={faCircleUser} />
                            </div>
                            <p>{profileName}</p>
                        </div>
                    </div>
                </div>
            </div>
        </RootStyle>
    )
}

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: "none",
    backdropFilter: "blur(6px)",
    backgroundColor: alpha(theme.palette.background.paper, 0.72),
}));
