import { Text, View} from "@react-pdf/renderer";
import PDFDeductions from "../pdfRender/PdfDeductions";
import PDFTaxes from "../pdfRender/PdfTaxes";
import moment from 'moment';
import styles from "../pdfRender/PDFStyles";

const numberFormat = function (number: number) {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(number);
};
const volumeFormat = function (number: number) {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3, }).format(number);
};

const PDFProperties = ( properties: any ) => {

    return (
        <View>
            <View style={styles.paddingTop15} wrap={false}></View>
            <View style={styles.sctSubTableHeader} wrap={false} >
                <View style={styles.ColumnSubTableHdr8Prop}>
                    <Text>Sale Date</Text>
                </View>
                <View style={styles.ColumnSubTableHdr5Prop}>
                    <Text>Int.</Text>
                </View>
                <View style={styles.ColumnSubTableHdr13Prop}>
                    <Text>Desc.</Text>
                </View>
                <View style={styles.ColumnSubTableHdr13Prop}>
                    <Text>Ded/Tax</Text>
                </View>
                <View style={styles.ColumnSubTableHdr9Prop}>
                    <Text>BTU</Text>
                </View>
                <View style={styles.ColumnSubTableHdr9Prop}>
                    <Text>Volume</Text>
                </View>
                <View style={styles.ColumnSubTableHdr9Prop}>
                    <Text>Price</Text>
                </View>
                <View style={styles.ColumnSubTableHdr9Prop}>
                    <Text>Value</Text>
                </View>
                <View style={styles.ColumnSubTableHdr9Prop}>
                    <Text>Disb Decimal</Text>
                </View>
                <View style={styles.ColumnSubTableHdr7Prop}>
                    <Text>NRI %</Text>
                </View>
                <View style={styles.ColumnSubTableHdr9Prop}>
                    <Text>Volume</Text>
                </View>
                <View style={styles.ColumnSubTableHdr9Prop}>
                    <Text>Value</Text>
                </View>
            </View>
            {properties.properties.checkDetails.map((detail: any, index: any) => {
                return(
                    <View key={index}>
                        <View style={styles.sctSubTableInfo} wrap={false}>
                            <View style={styles.ColSubTableInfoTxt8Prop}>
                                <Text>{moment(detail.saleDate).format('MM/DD/YYYY')}</Text>
                            </View>
                            <View style={styles.ColSubTableInfoTxt5Prop}>
                                <Text>{detail.interestType}</Text>
                            </View>
                            <View style={styles.ColSubTableInfoTxt13Prop}>
                                <Text>{detail.interestTypeDesc}</Text>
                            </View>
                            <View style={styles.ColSubTableInfoTxt13Prop}>
                                <Text></Text>
                            </View>
                            <View style={styles.ColSubTableInfoNo9Prop}>
                                <Text>{numberFormat(detail.heatingValue)}</Text>
                            </View>
                            <View style={styles.ColSubTableInfoNo9Prop}>
                                <Text>{volumeFormat(detail.grossVolume)}</Text>
                            </View>
                            <View style={styles.ColSubTableInfoNo9Prop}>
                                <Text>{numberFormat(detail.price)}</Text>
                            </View>
                            <View style={styles.ColSubTableInfoNo9Prop}>
                                <Text>{numberFormat(detail.grossValue)}</Text>
                            </View>
                            <View style={styles.ColSubTableInfoNo9Prop}>
                                <Text>{numberFormat(detail.disbDecimal)}</Text>
                            </View>
                            <View style={styles.ColSubTableInfoNo7Prop}>
                                <Text>{numberFormat(detail.nriDecimal)}</Text>
                            </View>
                            <View style={styles.ColSubTableInfoNo9Prop}>
                                <Text>{volumeFormat(detail.ownerVolume)}</Text>
                            </View>
                            <View style={styles.ColSubTableInfoNo9Prop}>
                                <Text>{numberFormat(detail.ownerNetValue)}</Text>
                            </View>
                        </View>
                        <View>
                            <PDFDeductions deductions={detail.deductions}></PDFDeductions>
                        </View>
                        <View>
                            <PDFTaxes taxes={detail.taxs}></PDFTaxes>
                        </View>
                    </View>
                )
            })}
            
        </View>
    );
};

export default PDFProperties;