import ReactApexChart from 'react-apexcharts';
import React from 'react';
import { jsx } from '@emotion/react';

const ApexChartComponent = (props: any): JSX.Element => {
    const { options, series  } = props;
  return (
          <ReactApexChart options={options} type="bar" series={series} height={364} width="100%" />
  );
};

export default ApexChartComponent;
