import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import DocuPDF from "../pdfRender/PdfDownload";
import { useParams } from 'react-router-dom';
import { useCheckDetailByDocSys } from '../../hooks/useCheckDetail';
import Loading from "../dashboard/LoadingComponent"

const PdfViewPage = () => { 
    const paramDocSys = useParams();
    const { checkDetail } = useCheckDetailByDocSys(paramDocSys);

    if (paramDocSys.pdfOption === "1") {
        return (<PDFViewer >
                    <DocuPDF docSystemNoParent={checkDetail.listItems} />
                </PDFViewer>)
    }
    else if (paramDocSys.pdfOption === "2") {
        return (
            <div>
                <div><Loading></Loading></div>
                <div>
                    <PDFDownloadLink document={<DocuPDF docSystemNoParent={checkDetail.listItems} />} fileName={`${paramDocSys.docSystemNo}.pdf`}>
                        {({ blob, url, loading, error }) => {
                            if(!loading && url && blob){
                                const link = document.createElement('a');
                                console.log("blob:" + blob);
                                console.log("url:" + url);
                                link.href = window.URL.createObjectURL(blob);
                                link.click();
                                link.remove();
                            }
                        } }
                    </PDFDownloadLink>
                </div>
            </div>
        )
    }
    else {
        return (<PDFViewer>
                    <DocuPDF docSystemNoParent={checkDetail.listItems} />
                </PDFViewer>)
    }
}

export default PdfViewPage;