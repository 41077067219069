import CheckDollarIcon from "../assets/icons/money-check-dollar.svg";
import Button from '@mui/material/Button';
import { Breadcrumbs } from "@mui/material";
interface props {
  title: string | any;
  ownerNumber: number | string;
  backPage: boolean;
  bp_name: string | any;
  page_name: string;
  sbp_link: any;
  bp_link: any;
  icon: boolean;
  pdfbutton: boolean;
  pdflink: string | any;
  sbp_name: string;
  page_nameProperty: boolean;
  docSysNo: any;
}

const MainTitle = ({
  title,
  ownerNumber,
  backPage,
  bp_name,
  sbp_link,
  bp_link,
  page_name,
  icon,
  pdfbutton,
  pdflink,
  sbp_name,
  page_nameProperty,
  docSysNo,
}: props) => {
  return (
    <div className="d-flex fm_mainTitle justify-content-between">
      <div className="d-flex align-items-center">
        {icon === true && (
          <div className="fm_mainTitle--icon">
            <img src={CheckDollarIcon} alt="" />
          </div>
        )}
        <div>
          {backPage === true && (
            <Breadcrumbs className="">
              <a className="fm-button--link" onClick={bp_link}>
                {bp_name}
              </a>
              {page_nameProperty === true && (
                <a className="fm-button--link" onClick={sbp_link}>
                  {sbp_name}
                </a>
              )}
              <p className="fm_mainTitle__bc--np">{page_name}</p>
            </Breadcrumbs>
          )}
          <h1>{title}</h1>
          {ownerNumber !== "" && <p className="orderNumber">{ownerNumber}</p>}
        </div>
      </div>
      <div className="d-flex align-items-center fm_mainTitle--pdf">
        {pdfbutton === true && (
            <Button variant="outlined" size="large" className="fm-button--link--border" onClick={pdflink}>
               View PDF
            </Button>
        )}
      </div>
    </div>
  );
};

MainTitle.defaultProps = {
  title: "",
  ownerNumber: "",
  backPage: true,
  sbp_link: "",
  bp_link: "",
  bp_name: "Back",
  page_name: "",
  icon: false,
  pdfbutton: false,
  pdflink: "",
  sbp_name: "",
  page_nameProperty: false,
  docSysNo: "",
};

export default MainTitle;
