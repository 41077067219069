import React, { useState, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton } from '@mui/material';
import { useCheckDetailByDocSys } from '../../hooks/useCheckDetail';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import FmTitle from '../MainTitle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';

const CheckDetailComponent = () => {
    const [showDetails, setShowDetails] = useState(false);
    const paramDocSys = useParams();
    const navigate = useNavigate();
    const [openAllItems, setOpenAllItems] = useState(true);
    const { checkDetail } = useCheckDetailByDocSys(paramDocSys);
    const [selected, setSelected] = useState<readonly string[]>([]);
    let itemParsed = moment(checkDetail.listItems.documentDate);
    let itemDate = itemParsed.format('MM/DD/YYYY');

    const onPdf = async (docSystemNo: any) => {
        window.open(`/ViewPDF/${docSystemNo}/2`, '_blank');
    };

    useEffect(() => {
        routerIndex();
    });

    function routerIndex() {
        if (checkDetail.listItems.length !== 0) {
            setShowDetails(true);
        }
    }
    const handleBackCheckHeader = () => {
        navigate(`/CheckView/${paramDocSys.id}/${paramDocSys.docTypeId}/${paramDocSys.companyName}`);
    };

    const handleBack = () => {
        navigate('/Dashboard');
    };

    const numberFormat = function (number: number) {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(number);
    };

    const validateiconProperty = () => {
      if(checkDetail.listItems[0].chHdrDetails.length === selected.length){
        setOpenAllItems(false)
      } else if (selected.length === 0) {
        setOpenAllItems(true)
      }
    }
    
    const handleSelectAllClick = () => {
          setOpenAllItems(!openAllItems);
          if (openAllItems) {
        const newSelecteds = checkDetail.listItems[0].chHdrDetails.map((n: any, index: any) => index);
          setSelected(newSelecteds);
          }else{
            setSelected([]);
          }
          
    }

    useEffect(() => {
      if(checkDetail.listItems.length !== 0){
        validateiconProperty();
      }
    }, [selected]);

    return (
      <div>
        {checkDetail.listItems.map((grandpa:any, jindex:any) => {
          return (<div key={jindex}>
            <FmTitle
              title={grandpa.bpName}
              ownerNumber={grandpa.businessPartnerNo}
              icon={true}
              backPage={true}
              bp_name={"Dashboard"}
              sbp_name={"Check view"}
              sbp_link={() => handleBackCheckHeader()}
              bp_link={() => handleBack()}
              page_name={"Check View Detail"}
              pdfbutton={true}
              pdflink={() => onPdf(grandpa.docSystemNo)}
              page_nameProperty={true}
              docSysNo={grandpa}
            />
            <div className="fm_cvD_tData ">
              <div className="d-flex fm_cvD_tData__c">
                <div className="col-md-auto">
                  <p>Check Date</p>
                  <h3>{itemDate}</h3>
                </div>
                <div className="col-md-auto">
                  <p>Check Number</p>
                  <h3>{grandpa.documentNo}</h3>
                </div>
              </div>
              <div className="d-flex fm_cvD_tData__c">
                <div className="d-flex fm_cvD_tData__c--sc">
                  <div className="col-md-auto fm_cvD_tData__c--sc--c} fm_cvD_tData__c--green">
                    <p>Gross Amount</p>
                    <h3>{numberFormat(grandpa.grossValue)}</h3>
                  </div>
                  <div className="col-md-auto fm_cvD_tData__c--sc--c} fm_cvD_tData__c--red">
                    <p>Tax</p>
                    <h3>{numberFormat(grandpa.taxValue)}</h3>
                  </div>
                </div>
                <div className="d-flex fm_cvD_tData__c--sc">
                  <div className="col-md-auto fm_cvD_tData__c--sc--c} fm_cvD_tData__c--red">
                    <p>Deductions</p>
                    <h3>{numberFormat(grandpa.deductionValue)}</h3>
                  </div>
                  <div className="col-md-auto fm_cvD_tData__c--sc--c}">
                    <p>Check Amount</p>
                    <h3>{numberFormat(grandpa.netValue)}</h3>
                  </div>
                </div>
              </div>
            </div>
    
            <div className="fm_card">
              {showDetails && (
                <TableContainer>
                  <Table className="fm_table" size="small">
                    <TableHead className="fm_table--head">
                      <TableRow>
                        <TableCell className="fm_tb_name col-1">
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleSelectAllClick()}
                            className="col-auto fm-white-icon"
                            color="primary"
                          >
                           {openAllItems ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell className="fm_tb_name col-1">
                          Property Number
                        </TableCell>
                        <TableCell className="fm_tb_name col-2">
                          Property Name
                        </TableCell>
                        <TableCell className="fm_tb_name col-1">State</TableCell>
                        <TableCell className="fm_tb_name col-1">County</TableCell>
                        <TableCell className="fm_tb_name col-1">Product</TableCell>
                        <TableCell className="fm_tb_name col-1">
                          Product Name
                        </TableCell>
                        <TableCell className="fm_tb_name col-1 text-end">
                          Volume
                        </TableCell>
                        <TableCell className="fm_tb_name col-1 text-end">
                          Value
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {grandpa.chHdrDetails.map(
                        (rowFather: any, index: any) => (
                          <Row
                            key={index}
                            row={rowFather}
                            collapseAll={openAllItems}
                            indexProp={index} setSelectedProp={setSelected} 
                            selectedProp={selected}
                          />
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          </div>);
        })}
      </div>
    );
};

export default CheckDetailComponent;

function Row(props: any) {
    const { row, collapseAll, indexProp, setSelectedProp, selectedProp } = props;
    const [open, setOpen] = React.useState(collapseAll);
    React.useEffect(() => {
        setOpen(collapseAll);
    }, [collapseAll]);
    const numberFormtRowTotal = function (number: number) {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(number);
    }

    const volumeFormatRowTotal = function (number: number) {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3, }).format(number);
    }

    const handleRowCollapse = () => {
      handleClick(indexProp)
      setOpen(!open)
    }

    const handleClick = (indexRowId: string) => {
        const selectedIndex = selectedProp.indexOf(indexRowId);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedProp, indexRowId);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedProp.slice(1));
        } else if (selectedIndex === selectedProp.length - 1) {
        newSelected = newSelected.concat(selectedProp.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selectedProp.slice(0, selectedIndex),
            selectedProp.slice(selectedIndex + 1),
        );
        }

        setSelectedProp(newSelected);
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'none' } }}>
                <TableCell className="col-auto">
                    <IconButton aria-label="expand row" size="small" onClick={() => handleRowCollapse()} className="col-auto">
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell className=" fm_tb_cell">{row.propertyNo}</TableCell>
                <TableCell className=" fm_tb_cell">{row.propertyName1}</TableCell>
                <TableCell className=" fm_tb_cell">{row.stateName}</TableCell>
                <TableCell className=" fm_tb_cell">{row.countyName}</TableCell>
                <TableCell className=" fm_tb_cell">{row.productCode}</TableCell>
                <TableCell className=" fm_tb_cell">{row.productName}</TableCell>
                <TableCell className=" fm_tb_cell text-end">{volumeFormatRowTotal(row.checkDetailTotalVolume)}</TableCell>
                <TableCell className=" fm_tb_cell text-end">{numberFormtRowTotal(row.checkDetailTotalValue)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell className="fm_subtable" colSpan={13}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    <SubRowNames />
                                    {row.checkDetails.map((rowChildren: any, index: any) => (
                                        <RowDetail key={index} row={rowChildren} colSpan={13} />
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function SubRowNames() {
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'none' } }}>
                <TableCell className="fm_subtable--subcell--name">SaleDate</TableCell>
                <TableCell className="fm_subtable--subcell--name">Int.</TableCell>
                <TableCell className="fm_subtable--subcell--name">Desc.</TableCell>
                <TableCell className="fm_subtable--subcell--name">Ded/Tax</TableCell>
                <TableCell className="fm_subtable--subcell--name">Desc.</TableCell>
                <TableCell className="fm_subtable--subcell--name text-end">BTU</TableCell>
                <TableCell className="fm_subtable--subcell--name text-end">Gross Volume</TableCell>
                <TableCell className="fm_subtable--subcell--name text-end">Price</TableCell>
                <TableCell className="fm_subtable--subcell--name text-end">Gross Value</TableCell>
                <TableCell className="fm_subtable--subcell--name text-end">Disb Decimal</TableCell>
                <TableCell className="fm_subtable--subcell--name text-end">NRI %</TableCell>
                <TableCell className="fm_subtable--subcell--name text-end">Owner Volume</TableCell>
                <TableCell className="fm_subtable--subcell--name text-end">Owner Value</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function RowDetail(props: any) {
    const { row } = props;
    const numbFormtRowDetail = function (number: number) {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(number);
    };

    const volumeFormat = function (number: number) {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3, }).format(number);
    };
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "none" } }}>
          <TableCell className="fm_subtable--subcell ">
            {moment(row.saleDate).format("MM/DD/YYYY")}
          </TableCell>
          <TableCell className="fm_subtable--subcell ">
            {row.interestType}
          </TableCell>
          <TableCell className="fm_subtable--subcell ">
            {row.interestTypeDesc}
          </TableCell>
          <TableCell className="fm_subtable--subcell " />
          <TableCell className="fm_subtable--subcell " />
          <TableCell className="fm_subtable--subcell text-end">
            {numbFormtRowDetail(row.heatingValue)}
          </TableCell>
          <TableCell className="fm_subtable--subcell text-end">
            {volumeFormat(row.grossVolume)}
          </TableCell>
          <TableCell className="fm_subtable--subcell text-end">
            {numbFormtRowDetail(row.price)}
          </TableCell>
          <TableCell className="fm_subtable--subcell text-end">
            {numbFormtRowDetail(row.grossValue)}
          </TableCell>
          <TableCell className="fm_subtable--subcell text-end">
            {numbFormtRowDetail(row.disbDecimal)}
          </TableCell>
          <TableCell className="fm_subtable--subcell text-end">
            {numbFormtRowDetail(row.nriDecimal)}
          </TableCell>
          <TableCell className="fm_subtable--subcell text-end">
            {volumeFormat(row.ownerVolume)}
          </TableCell>
          <TableCell className="fm_subtable--subcell text-end">
            {numbFormtRowDetail(row.ownerNetValue)}
          </TableCell>
        </TableRow>
        {row.deductions.map((detailsRow: any, lIndex : any) => (
          <TableRow
            key={lIndex}
            sx={{ "& > *": { borderBottom: "none" } }}
          >
            <TableCell className="fm_subtable--subcell " colSpan={3} />
            <TableCell className="fm_subtable--subcell " colSpan={1}>
              {detailsRow.deductionType}
            </TableCell>
            <TableCell className="fm_subtable--subcell " colSpan={1}>
              {detailsRow.dedTypeDesc}
            </TableCell>
            <TableCell className="fm_subtable--subcell " colSpan={3} />
            <TableCell className="fm_subtable--subcell text-end" colSpan={1}>
              {numbFormtRowDetail(detailsRow.grossDedValue)}
            </TableCell>
            <TableCell className="fm_subtable--subcell " colSpan={3} />
            <TableCell className="fm_subtable--subcell text-end" colSpan={1}>
              {numbFormtRowDetail(detailsRow.ownerDedValue)}
            </TableCell>
          </TableRow>
        ))}
        {row.taxs.map((detailsRow: any, mIndex: any) => (
          <TableRow
            key={mIndex}
            sx={{ "& > *": { borderBottom: "none" } }}
          >
            <TableCell className="fm_subtable--subcell " colSpan={3} />
            <TableCell className="fm_subtable--subcell" colSpan={1}>
              {detailsRow.taxType}
            </TableCell>
            <TableCell className="fm_subtable--subcell " colSpan={1}>
              {detailsRow.taxTypeDesc}
            </TableCell>
            <TableCell className="fm_subtable--subcell " colSpan={3} />
            <TableCell className="fm_subtable--subcell text-end" colSpan={1}>
              {numbFormtRowDetail(detailsRow.grossTaxValue)}
            </TableCell>
            <TableCell className="fm_subtable--subcell " colSpan={3} />
            <TableCell className="fm_subtable--subcell text-end" colSpan={1}>
              {numbFormtRowDetail(detailsRow.ownerTaxValue)}
            </TableCell>
          </TableRow>
        ))}
      </React.Fragment>
    );
}