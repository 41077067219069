import React from 'react';

const UserComponent = ({...props}) => {
    return (
        <div style={{margin: '100px'}}>
            <p>Welcome to Users</p>
        </div>
    )
}

export default UserComponent

