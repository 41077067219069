import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Collapse,
  IconButton,
  Table,
  FormControl,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useCheckProperty } from "../../hooks/useCheckProperty";
import Pagination from '@mui/material/Pagination';
import Stack from "@mui/material/Stack";
import moment from "moment";

const CheckPropertyComponent = (props: any) => {
  const [showDetails, setShowDetails] = useState(false);
  const { selection, setLimitPages, limitPages } = props;
  
  const [currentPage, setCurrentPage] = useState(1);
  const [openAllItems, setOpenAllItems] = useState(true);
  const [selected, setSelected] = useState<readonly string[]>([]);

  const handleChange = (event: any) => {
    setLimitPages(event.target.value);
    setCurrentPage(1);
  }      
  const handleCurrentPage = (event: React.ChangeEvent<unknown>, value: number) => {
      setCurrentPage(value);
  }
  
  const { checkProperty, totalPage } = useCheckProperty(selection, currentPage,
    limitPages, setCurrentPage);

  useEffect(() => {
    if (checkProperty.listItems.length !== 0) {
      routerIndex();
    }
  }, [checkProperty.listItems, currentPage]);

  function routerIndex() {
    setShowDetails(true);
    setSelected([]);
  }

  const validateiconHeader = () => {
    if(checkProperty.listItems.length === selected.length){
      setOpenAllItems(false)
    } else if (selected.length === 0) {
      setOpenAllItems(true)
    }
  }
  
  const handleSelectAllClick = () => {
        setOpenAllItems(!openAllItems);
        if (openAllItems) {
       const newSelecteds = checkProperty.listItems.map((n: any, index: any) => index);
        setSelected(newSelecteds);
        }else{
          setSelected([]);
        }
        
    }
  
  useEffect(() => {
    validateiconHeader();
  }, [selected]);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" className="fm_table" size="small">
          <TableHead className="fm_table--head">
            <TableRow>
              <TableCell className="fm_tb_name">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => handleSelectAllClick()}
                  className="col-auto fm-white-icon"
                  color="primary"
                >
                  {openAllItems ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell className="fm_tb_name " colSpan={1}>
                Check Month
              </TableCell>
              <TableCell className="fm_tb_name " colSpan={1}>
                Property Number
              </TableCell>
              <TableCell className="fm_tb_name " colSpan={1}>
                Property Name
              </TableCell>
              <TableCell className="fm_tb_name text-end" colSpan={1}>
                Gross Amount
              </TableCell>
              <TableCell className="fm_tb_name text-end" colSpan={1}>
                Tax
              </TableCell>
              <TableCell className="fm_tb_name text-end" colSpan={1}>
                Deductions
              </TableCell>
              <TableCell className="fm_tb_name text-end" colSpan={1}>
                Total Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {checkProperty.listItems.map((row: any, index: any) => (
              <Row key={index} row={row} collapseAll={openAllItems} indexProp={index} setSelectedProp={setSelected} selectedProp={selected}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="row justify-content-end fm_table--pagination">
        <div className="col-md-auto col-sm-12 justify-content-center">
          <div className="d-flex w-100 align-items-center justify-content-center fm_table--pagination--rows">
            <p className="fm_paragraph">Rows per page</p>
            <FormControl className="d-flex">
              <Select
                className="fm_table--pagination--rows--input"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={limitPages}
                onChange={handleChange}
              >
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="col-md-auto col-sm-12 align-self-center justify-content-center">
          <div className="d-flex w-100 align-items-center justify-content-center">
            {showDetails && (
              <Stack>
                <Pagination
                  count={totalPage}
                  page={currentPage}
                  onChange={handleCurrentPage}
                  showFirstButton
                  showLastButton
                  color="primary"
                />
              </Stack>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckPropertyComponent;

function Row(props: any) {
  const { row, collapseAll, indexProp, setSelectedProp, selectedProp } = props;
  const [open, setOpen] = React.useState(collapseAll);
  React.useEffect(() => {
    setOpen(collapseAll);
  }, [collapseAll]);

  const handleRowCollapse = () => {
    handleClick(indexProp)
    setOpen(!open)
  }

  const handleClick = (indexRowId: string) => {
        const selectedIndex = selectedProp.indexOf(indexRowId);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedProp, indexRowId);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedProp.slice(1));
        } else if (selectedIndex === selectedProp.length - 1) {
        newSelected = newSelected.concat(selectedProp.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selectedProp.slice(0, selectedIndex),
            selectedProp.slice(selectedIndex + 1),
        );
        }

        setSelectedProp(newSelected);
  }

  const numberFormat = function (number: number) {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(number);
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "none" } }}>
        <TableCell className=" col-auto">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleRowCollapse()}
            className="col-auto"
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell colSpan={1} className="">
          {moment(row.monthDate).format("MM/YYYY")}
        </TableCell>
        <TableCell colSpan={1} className="">{row.propertyNo}</TableCell>
        <TableCell colSpan={1} className="">{row.propertyName}</TableCell>
        <TableCell colSpan={1} className=" text-end">{numberFormat(row.grossAmount)}</TableCell>
        <TableCell colSpan={1} className=" text-end">{numberFormat(row.tax)}</TableCell>
        <TableCell colSpan={1} className=" text-end">{numberFormat(row.deduction)}</TableCell>
        <TableCell colSpan={1} className=" text-end">{numberFormat(row.totalAmountM)}</TableCell>
      </TableRow>
      <TableRow className="fm_subtable">
        <TableCell style={{ padding: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>

                    <TableCell className="fm_subtable--subcell--name">
                      Check Date
                    </TableCell>
                    <TableCell className="fm_subtable--subcell--name">
                      Property Number
                    </TableCell>
                    <TableCell className="fm_subtable--subcell--name">
                      Property Name
                    </TableCell>
                    <TableCell className="fm_subtable--subcell--name">
                      Check Number
                    </TableCell>
                    <TableCell className="fm_subtable--subcell--name">
                      Owner Number
                    </TableCell>
                    <TableCell className="fm_subtable--subcell--name">
                      Owner Name
                    </TableCell>
                    <TableCell className="fm_subtable--subcell--name text-end">
                      Gross Amount
                    </TableCell>
                    <TableCell className="fm_subtable--subcell--name text-end">
                      Tax
                    </TableCell>
                    <TableCell className="fm_subtable--subcell--name text-end">
                      Deductions
                    </TableCell>
                    <TableCell className="fm_subtable--subcell--name text-end">
                      Total Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.details.map((detailsRow: any, index: any) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        className="col-auto fm_subtable--subcell"
                        scope="row"
                      >
                        {moment(detailsRow.checkDate).format("MM/DD/YYYY")}
                      </TableCell>

                      <TableCell className="fm_subtable--subcell">
                        {detailsRow.propertyNo}
                      </TableCell>
                      <TableCell className="fm_subtable--subcell">
                        {detailsRow.propertyName}
                      </TableCell>
                      <TableCell className="fm_subtable--subcell">
                        {detailsRow.checkNumber}
                      </TableCell>
                      <TableCell className="fm_subtable--subcell">
                        {detailsRow.ownerNumber}
                      </TableCell>
                      <TableCell className="fm_subtable--subcell">
                        {detailsRow.ownerName}
                      </TableCell>
                      <TableCell className="fm_subtable--subcell text-end">
                        {numberFormat(detailsRow.grossAmount)}
                      </TableCell>
                      <TableCell className="fm_subtable--subcell text-end">
                        {numberFormat(detailsRow.tax)}
                      </TableCell>
                      <TableCell className="fm_subtable--subcell text-end">
                        {numberFormat(detailsRow.deduction)}
                      </TableCell>
                      <TableCell className="fm_subtable--subcell text-end">
                        {numberFormat(detailsRow.totalAmount)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
