import { useState, useEffect } from 'react'
import * as service from '../energilityServices/checkViewService/services/checkProperty.service'

export const useCheckProperty = (selection: any, page: any, limitPages: any, setCurrentPage: any) => {
  const setTimeInitial = 300;
  const [checkProperty, setCheckProperty] = useState({
    listItems: [] as any
  });
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
      if (page > 0){
        const timeout = setTimeout(() => {
          getCheckProperty();
        }, setTimeInitial)
        return () => clearTimeout(timeout)
      }
  }, [page, selection, limitPages]);

  useEffect(() => {
    if (selection.fromDate !== '' && selection.toDate !== '') {
        setCurrentPage(1);
      }
  }, [selection.filters, selection.fromDate, selection.toDate]);
  
    const getCheckProperty = async() => {
        const response = await service.getCheckProperty(selection, page, limitPages);
        setTotalPage(response.totalPages)
        setCheckProperty({
          ...checkProperty,
          listItems: response.items
        });
    }

    return {
        checkProperty,
        totalPage
    }

}