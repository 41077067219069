import { StyleSheet } from "@react-pdf/renderer";

const PDFStyles = StyleSheet.create({
  page: { backgroundColor: 'white', fontSize: '8px', paddingTop: 30, paddingBottom:55, paddingLeft:30, paddingRight:30},
  sectionImage: {width: "30%", textAlign: 'right'},
  imageStyle: { maxWidth: "150", maxHeight: "50" },
  stnCompanyName: {width: "70%", color: "black", marginTop:10, fontSize: "16px"},
  sectionTop: { color: 'black', width: '100%', marginBottom:15, flexDirection: 'row'},
  section: { color: 'black', width: '100%', flexDirection: 'row'},
  sectionWidth30: {width: "30%"},
  sectionWidth35: {width: "35%"},
  ColumnText: { textAlign: 'left', width: "10%"},
  ColumnNumberRight: { textAlign: 'right', width: "20%"},
  ColumnAddressLeft: { textAlign: 'right', width: "20%"},
  sectUnderlineBottom: { color: 'black', width: '100%', flexDirection: 'row', marginTop:20, borderBottom: '2 solid #1D7699' },
  ColumnHeaderProperty20: {width: "20%", fontWeight:"bold"},
  ColumnHeaderProperty15: {width: "15%"},
  ColumnHeaderProperty10: {width: "10%"},
  sectionPropertyHeader: { color: 'black', width: '100%', flexDirection: 'row'},
  sctSubTableNetValue: {color: 'black', width: '100%', flexDirection: 'row', borderTop: '2 solid #8CC63F'},
  ColSubTableInfoTxt13: {width: "12%", textAlign:"left"},
  ColSubTableInfoTxt41: {width: "35%", textAlign:"left"},
  ColSubTableInfoTxt16: {width: "18%", textAlign:"right"},
  ColSubTableInfoNo7: {width: "7%", textAlign:"right", paddingLeft: "2px", paddingRight:"2px"},
  ColSubTableInfoTxt18: {width: "18%", textAlign:"left"},
  ColSubTableInfoNo9: {width: "9%", textAlign:"right", paddingLeft: "2px", paddingRight:"2px"},
  sectionForTotals: { color: 'black', textAlign: 'right', paddingTop: 30, width: "100%", flexDirection: 'row'},
  stnWidth48: {width: "60%"},
  stnHdrTotal: {width: "40%", textAlign: "center", borderBottom: "2 solid #8CC63F"},
  sctnTotals: { color: 'black', textAlign: 'right', width: "100%", flexDirection: 'row'  },
  stnInfoTotal: {width: "40%", textAlign: "left", flexDirection: 'row'},  
  stnTextTotals:{ width: "50%"},
  stnNumberTotals:{width: "50%", textAlign:"right"},
  pageNumber: { position: 'absolute', fontSize: '8px', bottom: 0, left: 30, right: 30, top:20, textAlign: 'right', color: 'black'},
  pageNumbers: { position: 'absolute', bottom: 20,left: 30,right: 0, textAlign: 'left', color:'black'}, 
  paddingTop15: { paddingTop: 10 },
  stcFooter: { backgroundColor: 'white' },
  footerStyles: { color: 'black', fontSize: '8' },
  sctSubTableHeader: {color: 'black', width: '100%', flexDirection: 'row', borderBottom: '2 solid #1D7699'},
  sctSubTableInfo: {color: 'black', width: '100%', flexDirection: 'row'},
  ColumnSubTableHdr5Prop: {width: "5%", textAlign:"left"},
  ColumnSubTableHdr7Prop: {width: "7%", textAlign:"right", paddingRight: "3px"},
  ColumnSubTableHdr8Prop: {width: "7%", textAlign:"left"},
  ColumnSubTableHdr9Prop: {width: "9%", textAlign:"right", paddingRight: "3px"},
  ColumnSubTableHdr13Prop: {width: "13%", textAlign:"left"},
  ColSubTableInfoTxt5Prop: {width: "5%", textAlign:"left"},
  ColSubTableInfoTxt8Prop: {width: "7%", textAlign:"left"},
  ColSubTableInfoNo7Prop: {width: "7%", textAlign:"right", paddingLeft: "2px", paddingRight:"2px"},
  ColSubTableInfoNo9Prop: {width: "9%", textAlign:"right", paddingLeft: "2px", paddingRight:"2px"},
  ColSubTableInfoTxt13Prop: {width: "13%", textAlign:"left"}, 
  ColSubTableInfoDedTaxTxt8: {width: "7%", textAlign:"left"},
  ColSubTableInfoDedTaxTxt5: {width: "5%", textAlign:"left"},
  ColSubTableInfoDedTaxTxt13: {width: "13%", textAlign:"left"},
  ColSubTableDedTaxDesc: {width: "31%", textAlign:"left"},
  ColSubTableInfoDedTaxNo9: {width: "9%", textAlign:"right", paddingLeft: "2px", paddingRight:"2px"},
  ColSubTableInfoDedTaxNo7: {width: "7%", textAlign:"right", paddingLeft: "2px", paddingRight:"2px"},
});

export default PDFStyles