import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import DashboardNavbarComponent from "./navigationConfig/DashboardNavbar.component";

export default function DashboardLayout() {

  return (
    <RootStyle>
      <DashboardNavbarComponent />
      <div className="container fm_main_container">
        <Outlet />
      </div>
    </RootStyle>
  );
}

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});
