import { useRoutes } from 'react-router-dom';
import CheckDetailComponent from '../components/chekView/CheckDetail.component';
import DashboardLayout from '../components/dashboard/Home.component';
import LoginComponent from '../components/login/Login.component';
import PassUpdateComponent from '../components/login/PassUpdate.component';
import RecoverPassComponent from '../components/login/RecoverPass.component';
import CheckViewComponent from '../components/CheckView.component';
import DashboardComponent from '../components/Dashboard.component';
import UserComponent from '../components/users/Users.component';
import ProtectedRoutes from '../components/login/ProtectedRoutes.component';
import ViewPdf from '../components/pdfRender/ViewPdf';

export default function RoutesApp() {
    return useRoutes([
        {
            path: '/',
            element: <LoginComponent />
        },
        {
            path: '/RecoverPassword',
            element: <RecoverPassComponent />
        },
        {
            path: '',
            element: <ProtectedRoutes />,
            children: [
                {
                    path: '/NewPassword',
                    element: <PassUpdateComponent />
                },
                {
                    path: '/ViewPDF/:docSystemNo/:pdfOption',
                    element: <ViewPdf />
                },
                {
                    path: '',
                    element: <DashboardLayout />,
                    children: [
                        { path: '/Dashboard', element: <DashboardComponent /> },
                        { path: '/User', element: <UserComponent /> },
                        { path: '/CheckView/:id/:docTypeId/:companyName', element: <CheckViewComponent /> },
                        { path: '/CheckDetails/:id/:docTypeId/:companyName/:docSystemNo', element: <CheckDetailComponent /> }
                    ]
                }
            ]
        }
    ]);
}
