import { useState, useEffect } from 'react';
import { TextField, Box, IconButton } from '@mui/material';
import CheckHeaderComponent from './chekView/CheckHeader.component';
import CheckPropertyComponent from './chekView/CheckProperty.component';
import { ICheckHeaderGraph, ICheckView, ICheckProperty, ICheckHeader } from '../energilityServices/checkViewService/model/ICheckView';
import { useParams } from 'react-router-dom';
import { LoadingEnum } from '../layout/gallerySlice';
import * as service from '../energilityServices/checkViewService/services/checkView.service';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment';
import FmTitle from './MainTitle';
import InputAdornment from '@mui/material/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import Button from '@mui/material/Button';
import SearchIcon from '../assets/icons/magnifying-glass.svg';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ApexChartComponent from './ApexChart.component';



const initialState: any = {
    loading: LoadingEnum.initial,
    error: ''
};
const initialInterface: ICheckHeaderGraph = {
    fromDate: '',
    toDate: '',
    companyId: '',
    docTypeId: '',
    filters: '',
    pageNumber: 1,
    limit: 6
};

const initialInterfHeader: ICheckHeader = {
    fromDate: '',
    toDate: '',
    companyId: '',
    docTypeId: '',
    filters: '',
    pageNumber: 1,
    limit: 6
};

const InterfaceProp: ICheckProperty = {
    fromDate: '',
    toDate: '',
    companyId: '',
    docTypeId: '',
    filters: ''
};
const initialValidateText: any = {
    textFrom: '',
    textTo: ''
};

const initialObjectGraph: any = {
    objectGraph: '',
};

const CheckViewComponent = () => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const infoCardId = useParams();
    const setTimeInitial = 300;
    const [initial, setInitial] = useState(initialState);
    const [state, setState] = useState<ICheckHeaderGraph>(initialInterface);
    const [stateHeader, setStateHeader] = useState<ICheckHeader>(initialInterfHeader);
    const [toDate, setToDate] = useState<Date | null>(new Date());
    const [checkProperty, setCheckProperty] = useState<ICheckProperty>(InterfaceProp);
    const [showDetails, setShowDetails] = useState(false);
    const [changePage, setChangePage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [errors, setErrors] = useState(initialValidateText);
    const dateNow = new Date();
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedBtnHeader, setSelectedBtnHeader] = useState('active');
    const [selectedBtnProperty, setSelectedBtnProperty] = useState('');
    const [selectedBarGraph, setSelectedBarGraph] = useState(initialObjectGraph);
    const [limitPages, setLimitPages] = useState(25);
    const [selectedView, setSelectedView] = useState<readonly string[]>([]);
    var dataPointId = cookies.get('dataPointIndex');
    const handleChangeHeader = () => {
        setSelectedTab(0);
        resetInputField();
        setSelectedBtnHeader('active');
        setSelectedBtnProperty('');
    };

    const handleChangeProperty = () => {
        setSelectedTab(1);
        resetInputField();
        setSelectedBtnProperty('active');
        setSelectedBtnHeader('');
    };

    const [enableReturn, setEnableReturn] = useState(true);
    const [disableButtonGraph, setDisableButtonGraph] = useState(true);

    const resetInputField = () => {
        setState({ ...state, filters: '' });
        setStateHeader({ ...stateHeader, filters: '' });
        setCheckProperty({ ...checkProperty, filters: '' });
    };

    const date = new Date();
    const newDate = new Date(date.setMonth(date.getMonth() - 6));
    const [fromDate, setFromDate] = useState<Date | null>(new Date(newDate));

    const [burnDownChart, setBurnDownChart] = useState({
        chartSeries: [],
        ChartCategories: []
    });
    const graphSeries: any[] = [];
    const graphCategory: any[] = [];

    const setLoading = () => {
        setInitial((prevState: any) => ({
            ...prevState,
            loading: LoadingEnum.loading
        }));
    };

    useEffect(() => {
        if (initial.loading === LoadingEnum.initial) {
            setLoading();

            const initialdateFrom = new Date();
            const dateFormatCalculate = new Date(initialdateFrom.setMonth(initialdateFrom.getMonth() - 6));
            let parsedDateFrom = moment(dateFormatCalculate);
            let outputDateFrom = parsedDateFrom.format('YYYY-MM-DD');

            const initialdateTo = new Date();
            let parsedDateTo = moment(initialdateTo);
            let outputDateTo = parsedDateTo.format('YYYY-MM-DD');

            var initialValtfromDate = cookies.get('fromDate');
            var initialValtDate = cookies.get('toDate');
            var initialValtFilter = cookies.get('filters');
            cookies.remove("dataPointIndex", { path: "/" });
            
            if(initialValtfromDate !== undefined && initialValtDate !== undefined){
                let dateCookieTo = new Date(initialValtDate);
                let dateCookieFrom = new Date(initialValtfromDate);
               setState({...state,
                    companyId: infoCardId.id, docTypeId: infoCardId.docTypeId,
                    fromDate: initialValtfromDate, toDate: initialValtDate, filters: initialValtFilter});

                setStateHeader({...stateHeader,
                    companyId: infoCardId.id, docTypeId: infoCardId.docTypeId,
                    fromDate: initialValtfromDate, toDate: initialValtDate, filters: initialValtFilter});

                setCheckProperty({...checkProperty,
                    fromDate: initialValtfromDate, toDate: initialValtDate,
                    companyId: infoCardId.id, docTypeId: infoCardId.docTypeId, filters: initialValtFilter});
                setToDate(dateCookieTo);
                setFromDate(dateCookieFrom)
            }else{
                setState({...state,
                    companyId: infoCardId.id, docTypeId: infoCardId.docTypeId,
                    fromDate: outputDateFrom, toDate: outputDateTo});

                setStateHeader({...stateHeader,
                    companyId: infoCardId.id, docTypeId: infoCardId.docTypeId,
                    fromDate: outputDateFrom, toDate: outputDateTo});

                setCheckProperty({...checkProperty,
                    fromDate: outputDateFrom, toDate: outputDateTo,
                    companyId: infoCardId.id, docTypeId: infoCardId.docTypeId});
            }

            setInitial((prevState: any) => ({
                ...prevState,
                loading: LoadingEnum.ready,
                error: ''
            }));
        }
    }, [initial.loading]);

    const ApplyFilters = async () => {
        const response = await service.getCheckHeaderGraph(state);
        setTotalPages(response.totalPages);
        response.items.forEach((item: ICheckView) => {
            graphSeries.push({ monthYear: item.monthDate + '/' + item.yearDate });
            graphCategory.push({ totalAmount: item.totalAmount });
        });

        const listCategories = response.items.map((item: any, index: any) => {
            return graphSeries[index].monthYear;
        });

        const listSeries = response.items.map((item: any, index: any) => {
            return graphCategory[index].totalAmount;
        });

        setBurnDownChart({
            ...burnDownChart,
            chartSeries: listSeries,
            ChartCategories: listCategories
        });
    };

    const handleChangeFrom = (event: any) => {
        setFromDate(event);
        setErrors({ ...errors, textFrom: '' });
    };

    const HandleChangeTo = (event: any) => {
        setToDate(event);
        setErrors({ ...errors, textTo: '' });
    };

    const HandleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChangePage(1);
        setState({ ...state, filters: event.target.value });
        setStateHeader({ ...stateHeader, filters: event.target.value });
        setCheckProperty({
            ...checkProperty,
            filters: event.target.value
        });
    };

    useEffect(() => {
        if (state.toDate !== '' && state.fromDate !== '' && state.toDate !== 'Invalid date' && state.fromDate !== 'Invalid date') {
            let parsedDateFrom = moment(fromDate);
            let parsedDateTo = moment(toDate);
            let outputDateFrom = parsedDateFrom.format('YYYY-MM-DD');
            let outputDateTo = parsedDateTo.format('YYYY-MM-DD');
            if (outputDateFrom !== 'Invalid date' && outputDateTo !== 'Invalid date') {
                if(changePage === 1){
                    setState({ ...state, pageNumber: 1, fromDate: outputDateFrom, toDate: outputDateTo });
                }else{
                    setState({ ...state, pageNumber: changePage, fromDate: outputDateFrom, toDate: outputDateTo });
                }

                setStateHeader({ ...stateHeader, fromDate: outputDateFrom, toDate: outputDateTo });
                setCheckProperty({ ...checkProperty, fromDate: outputDateFrom, toDate: outputDateTo });
                listCheckHeader();
                const timeout = setTimeout(() => {
                ApplyFilters();
                }, setTimeInitial)
                return () => clearTimeout(timeout)
            }
        }
    }, [state.toDate, state.fromDate, state.pageNumber, state.filters, changePage, fromDate, toDate]);

    function listCheckHeader() {
        if (state.companyId !== '' && state.docTypeId !== '') {
            setShowDetails(true);
            setEnableReturn(true);
            if(selectedBarGraph.objectGraph !== "" &&  dataPointId !== undefined){
                selectedBarGraph.objectGraph.toggleDataPointSelection(0,dataPointId)    
                setDisableButtonGraph(true)
            }
            cookies.remove("dataPointIndex", { path: "/" });
        }
    }

    function listCheckProperty(dateChart: any) {
        const fomartChart = dateChart;
        if (fomartChart !== undefined) {
            setEnableReturn(false);
            var parts = fomartChart.split('/', 2);
            var month = parts[0];
            var year = parts[1];
            const formatDateChr = `${year}-${month}`;
            setCheckProperty({
                ...checkProperty,
                fromDate: formatDateChr,
                toDate: formatDateChr
            });
        }
    }

    const changeOneMothHeader = (dateChart: any) => {
        const fomartChart = dateChart;
        if (fomartChart !== undefined) {
            setEnableReturn(false);
            var parts = fomartChart.split('/', 2);
            var month = parts[0];
            var year = parts[1];
            const formatDateChr = `${year}-${month}`;
            setStateHeader({
                ...stateHeader,
                fromDate: formatDateChr,
                toDate: formatDateChr
            });
        }
    };

    var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
    });

    const handleBackButtonClick = () => {
        setChangePage(changePage - 1);
    };

    const handleNextButtonClick = () => {
        setChangePage(changePage + 1);
    };

    const refreshFunction = () => {
        setChangePage(1);
        setTotalPages(0);
    };

    const handleBack = () => {
        navigate('/Dashboard');
    };

    const handleChangeTextTo = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (validateDate(event.target.value)) {
            const formatTextTo = event.target.value;
            if (formatTextTo !== undefined) {
                var parts = formatTextTo.split('/', 2);
                var month = parts[0];
                var year = parts[1];
                const formatDateTextTo = `${year}-${month}`;

                setState({ ...state, toDate: formatDateTextTo });
                setStateHeader({ ...stateHeader, toDate: formatDateTextTo });
                setCheckProperty({ ...checkProperty, toDate: formatDateTextTo });
                setErrors({ ...errors, textTo: '' });
            }
        } else {
            setState({ ...state, toDate: 'Invalid date' });
            setErrors({ ...errors, textTo: 'Date to is invalid.' });
        }
    };

    const handleChangeTextFrom = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (validateDate(event.target.value)) {
            const formatTextFrom = event.target.value;
            if (formatTextFrom !== undefined) {
                var parts = formatTextFrom.split('/', 2);
                var month = parts[0];
                var year = parts[1];
                const formatDateTextFrom = `${year}-${month}`;
                setState({ ...state, fromDate: formatDateTextFrom });
                setStateHeader({ ...stateHeader, fromDate: formatDateTextFrom });
                setCheckProperty({ ...checkProperty, fromDate: formatDateTextFrom });
                setErrors({ ...errors, textFrom: '' });
            }
        } else {
            setState({ ...state, fromDate: 'Invalid date' });
            setErrors({ ...errors, textFrom: 'Date from is invalid.' });
        }
    };

    function validateDate(testdate: any) {
        var date_regex = /^(0[1-9]|10|11|12)[\/](19|20)[0-9]{2}$/;

        return date_regex.test(testdate);
    }

    const returnToPrevious = () => {
        setStateHeader({
            ...stateHeader,
            fromDate: state.fromDate,
            toDate: state.toDate
        });
        setCheckProperty({
            ...checkProperty,
            fromDate: state.fromDate,
            toDate: state.toDate
        });
        setEnableReturn(true);
        selectedBarGraph.objectGraph.toggleDataPointSelection(0,dataPointId)
        setDisableButtonGraph(true)
        cookies.remove("dataPointIndex", { path: "/" });
    };

    useEffect(() => {
        if (enableReturn === true && selectedBarGraph.objectGraph !== "" &&  dataPointId !== undefined) {
            selectedBarGraph.objectGraph.toggleDataPointSelection(0,dataPointId)
        }
    }, [enableReturn, changePage]);

    const getSelecIndex = (dateChart: any) => {
        const fomartChart = dateChart;
        if (fomartChart !== undefined) {
            setDisableButtonGraph(false)
        }
        
    };


    useEffect(() => {
        if (fromDate !== undefined && toDate !== undefined) {
            refreshFunction();
        }
    }, [fromDate, toDate]);

    const series = [
        {
            name: 'Inflation',
            data: burnDownChart.chartSeries
        }
    ];
    
    const options = {
        chart: {
            id: 'simple-bar',
            events: {
                updated: function (chartContext:any, options: any) {
                     if (enableReturn === true && selectedBarGraph.objectGraph !== "") {
                        selectedBarGraph.objectGraph.toggleDataPointSelection(0,dataPointId)
                    }
                },
                click: function (event: any, chartContext: any, config: any) {
                    var dataPoint = cookies.get('dataPointIndex');
                    let varObjectGraph = document.getElementById(event.target.id);
                    let dateChart = config.config.xaxis.categories[config.dataPointIndex];

                    if(config.dataPointIndex === parseInt(dataPoint) && dateChart !== undefined){
                        chartContext.toggleDataPointSelection(0,config.dataPointIndex)
                    }
                   
                    if(varObjectGraph?.innerHTML === '' && dateChart !== undefined){
                        setSelectedBarGraph({
                            ...selectedBarGraph,
                            objectGraph: chartContext
                        });
                        getSelecIndex(dateChart);
                        cookies.set('dataPointIndex', config.dataPointIndex, { path: '/' });
                    }
                     listCheckProperty(dateChart);
                    changeOneMothHeader(dateChart);
        
                },
            },
            
        },
        fill: {
            colors: ["#21a7fe"]
        },
        states: {
            active: {
                filter: {
                    type: 'darken'
                }
            },
        },

        tooltip: {
            enabled: true
        },
        xaxis: {
            categories: burnDownChart.ChartCategories,
            position: 'top'
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            labels: {
                show: false,
                formatter: function (val: any) {
                    return val + '%';
                }
            }
        },

        plotOptions: {
            bar: {
                borderRadius: 10,
                dataLabels: {
                    position: 'top'
                },
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val: any) {
                return formatter.format(val);

            },
            offsetY: -20,
            style: {
                fontSize: '14px',
                colors: ['#4D4D4D']
            }
        }
    };

    const onDownloadMultiplePdf = () => {
        window.open(`/ViewPDF/${selectedView}/2`, '_blank');
    }

    return (
            <div>
                <FmTitle title={infoCardId.companyName} backPage={true} bp_name={'Dashboard'} bp_link={() => handleBack()} page_name={'Check View'} page_nameProperty={false} />
                <div className="fm_card fm_checkview d-flex flex-column">
                    <div className="fm_cview_filters row g-3">
                        <TextField
                            className="fm_input col-lg-4 col-md-1"
                            label="Search"
                            name="search"
                            size="small"
                            variant="outlined"
                            value={state.filters}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment className="fa-xl fm_input--icon" position="start">
                                        <div className="fm_input--icon">
                                            <img src={SearchIcon} alt="" />
                                        </div>
                                    </InputAdornment>
                                )
                            }}
                            onChange={HandleSearch}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                inputFormat="MM/yyyy"
                                views={['year', 'month']}
                                label="From"
                                maxDate={new Date(dateNow)}
                                value={fromDate}
                                onChange={(newValue) => {
                                    handleChangeFrom(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="fm_input col-lg-2 col-md-1"
                                        size="small"
                                        variant="outlined"
                                        error={Boolean(errors?.textFrom)}
                                        helperText={errors?.textFrom}
                                        onChange={handleChangeTextFrom}
                                        sx={{
                                            svg: {
                                                nargin: '0',
                                                padding: '0',
                                                width: '24px',
                                                height: '24px',
                                                color: '#91969A'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                inputFormat="MM/yyyy"
                                views={['year', 'month']}
                                label="To"
                                minDate={fromDate}
                                maxDate={new Date(dateNow)}
                                value={toDate}
                                onChange={(newValue) => {
                                    HandleChangeTo(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="fm_input col-lg-2 col-md-1"
                                        size="small"
                                        variant="outlined"
                                        error={Boolean(errors?.textTo)}
                                        helperText={errors?.textTo}
                                        onChange={handleChangeTextTo}
                                        sx={{
                                            svg: {
                                                padding: '0',
                                                width: '24px',
                                                height: '24px',
                                                color: '#91969A'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="fn_char_container">
                        <div className="fm_char_buttons d-flex justify-content-between">
                            <Button className="fm-button--link" size="small" onClick={() => returnToPrevious()} disabled={enableReturn}>
                                View All Months
                            </Button>
                            
                            {disableButtonGraph && (
                            <div>
                                <IconButton className="fm_char_btn fa-xl" size="small" onClick={() => handleBackButtonClick()} disabled={changePage === 1}>
                                    <FontAwesomeIcon icon={faCaretLeft} />
                                </IconButton>
                                <IconButton className=" fm_char_btn fa-xl" size="small" onClick={() => handleNextButtonClick()} disabled={changePage >= totalPages}>
                                    <FontAwesomeIcon icon={faCaretRight} />
                                </IconButton>
                            </div>
                            )}
                        </div>
                        <Box sx={{ p: 3, pb: 1, width: '100%' }} dir="ltr"> 
                            <ApexChartComponent options={options} series={series}/>
                        </Box>
                    </div>
                    {showDetails && (
                        <div className="table">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex fm_table__selector">
                                    <Button variant="outlined" size="large" className={`col-4 col-sm-6 col-xl-8 fm-button--link--border ${selectedBtnHeader}`} onClick={() => handleChangeHeader()}>
                                        Check Header
                                    </Button>
                                    <Button variant="outlined" size="large" className={`col-4 col-sm-6 col-xl-8 fm-button--link--border ${selectedBtnProperty}`} onClick={() => handleChangeProperty()}>
                                        Property
                                    </Button>
                                </div>
                                {selectedTab === 0 &&
                                <Button variant="outlined" size="large" className={`col-2 col-sm-2 col-xxl-1 fm-button--link--border`} disabled={selectedView.length < 2} onClick={() => onDownloadMultiplePdf()}>
                                    View PDF
                                </Button>}
                            </div>
                            {selectedTab === 0 && <CheckHeaderComponent selection={stateHeader} companyName={infoCardId.companyName} setLimitPages = {(value: any) =>  setLimitPages(value)} 
                            limitPages={limitPages} setSelected= {(value: any) =>  setSelectedView(value)} selected={selectedView} maintainState={state}/>}
                            {selectedTab === 1 && <CheckPropertyComponent selection={checkProperty} setLimitPages = {(value: any) =>  setLimitPages(value)} limitPages={limitPages}/>}
                        </div>
                    )}
                </div>
            </div>
    );
};

export default CheckViewComponent;