import axios from 'axios';
import { Button, AlertTitle, TextField, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import md5 from 'md5';
import { ILogin } from '../../energilityServices/userProfile/model/IUser';

const interfaceState: ILogin = {
    UserId: '',
    Password: ''
};

const LoginComponent = (props: any) => {
    const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/Login`;
    const navigate = useNavigate();
    const cookies = new Cookies();
    const [formLogin, setFormLogin] = useState<ILogin>(interfaceState);
    const [alertTitle, setAlertTitle] = useState(false);
    const [alertPasswd, setAlertPasswd] = useState(false);
    const [validateScreen, setValidateScreen] = useState(false);

    useEffect(() => {
        var validateForgot = cookies.get('forgotPassword');
        if (cookies.get('userId') && validateForgot === 'true') {
            navigate('/NewPassword', { replace: true });
            setValidateScreen(false);
        } else {
            if (cookies.get('userId')) {
                navigate('/Dashboard', { replace: true });
                setValidateScreen(false);
            } else {
                setValidateScreen(true);
            }
        }
    });

    const handleKeywordKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            loginIn();
        }
    };

    const loginIn = async () => {
        if (formLogin.UserId !== '' && formLogin.Password !== '') {
            const validateUserId = await axios.get(baseUrl + `/ValidateEmail?email=${formLogin.UserId}`);
            var password = md5(formLogin.Password);
            const response = await axios.get(baseUrl + `/Login?email=${formLogin.UserId}&password=${password}`);

            if (response.data !== '' && validateUserId.data.response === true) {
                if (response.data.userId > 0) {
                    cookies.set('userId', response.data.userId, { path: '/' });
                    cookies.set('userName', response.data.userName, { path: '/' });
                    cookies.set('firstName', response.data.firstName, { path: '/' });
                    cookies.set('lastName', response.data.lastName, { path: '/' });
                    cookies.set('email', response.data.email, { path: '/' });
                    cookies.set('forgotPassword', response.data.forgotPassword, { path: '/' });
                    var validate = cookies.get('forgotPassword');
                    if (validate === 'true') {
                        navigate('/NewPassword', { replace: true });
                    } else {
                        navigate('/Dashboard', { replace: true });
                    }
                }
            } else {
                setAlertPasswd(true);
                setAlertTitle(false);
            }
        } else {
            setAlertTitle(true);
            setAlertPasswd(false);
        }
    };

    const handleChangeUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormLogin({ ...formLogin, UserId: event.target.value });
    };

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormLogin({ ...formLogin, Password: event.target.value });
    };
    return (
        <div className="container">
            {validateScreen === true && (
                <div className="d-flex flex-column align-content-center justify-content-center fm_login_container">
                    <div className="fm_login_bg"></div>
                    <div className="fm_login_head text-center">
                        <div className="col">
                            <h1 className="fm_login_head--title">Welcome!</h1>
                            <p className="fm_login_head--subtitle">Enter your user name and password to get access.</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="fm_login_card fm_card ">
                            <div className="d-flex flex-column justify-items-center">
                                <div className="d-flex w-100 justify-content-center">
                                    <div className="fm_login_card__logo fm-logo--big"></div>
                                </div>

                                <div className="fm_login_card__form  d-flex flex-column justify-content-center text-center">
                                    <h1 className="logincard__form__title text-center">Login</h1>
                                    <TextField
                                        label="User Name"
                                        className="fm_input"
                                        variant="outlined"
                                        type="UserId"
                                        name="UserId"
                                        size="small"
                                        onChange={handleChangeUserId}
                                        inputProps={{
                                            maxLength: 100
                                        }}
                                        onKeyPress={handleKeywordKeyPress}
                                    />
                                    <TextField
                                        className="fm_input"
                                        label="Password"
                                        name="Password"
                                        type="Password"
                                        size="small"
                                        variant="outlined"
                                        onChange={handleChangePassword}
                                        inputProps={{
                                            maxLength: 100
                                        }}
                                        onKeyPress={handleKeywordKeyPress}
                                    />
                                    <div>
                                        <a href="/RecoverPassword" className="fm-button--link">
                                            Forgot Password?
                                        </a>
                                    </div>
                                    <Button fullWidth size="large" variant="contained" className="fm-button--colored" color="primary" onClick={() => loginIn()}>
                                        Login
                                    </Button>
                                    {alertTitle && (
                                        <Alert severity="error">
                                            <AlertTitle>Please, verify that all fields are filled.</AlertTitle>
                                        </Alert>
                                    )}
                                    {alertPasswd && (
                                        <Alert severity="error">
                                            <AlertTitle>The user name or password is incorrect.</AlertTitle>
                                        </Alert>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LoginComponent;
