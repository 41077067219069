import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { IUserNewPassword } from "../../energilityServices/userProfile/model/IUser";
import * as service from "../../energilityServices/userProfile/services/user.service";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import md5 from "md5";

const interfaceState: IUserNewPassword = {
  Email: "",
  TemporalPassword: "",
  NewPassword: "",
};

const initialState: any = {
  email: "",
  newPassword: "",
  confirmPassword: "",
};

const PassUpdateComponent = (props: any) => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPass, setconfirmPass] = useState(false);
  const [alertTitle, setAlertTitle] = useState(false);
  const [errorSave, setErrorSave] = useState(false);
  const [password, setPassword] = useState(initialState);
  const [submit, setsubmit] = useState<IUserNewPassword>(interfaceState);
  const [errors, setErrors] = useState(initialState);
  const [enableButton, setEnableButton] = useState(true);

  const save = async () => {
    if (
      submit.Email !== "" &&
      submit.NewPassword !== "" &&
      submit.TemporalPassword !== ""
    ) {
      const response = await service.postNewPassword(submit);
      if (response !== '') {
        cookies.set('forgotPassword', false, { path: '/' });
        navigate("/Dashboard", { replace: true });
      } else {
        setErrorSave(true);
        setAlertTitle(false);
      }
    } else {
      setAlertTitle(true);
      setErrorSave(false);
    }
  };

  useEffect(() => {
    var validateForgot = cookies.get("forgotPassword");
    if (cookies.get("userId") && validateForgot !== "true") {
      navigate("/Dashboard", { replace: true });
    }
  });

  const handleChangeTemporal = (event: React.ChangeEvent<HTMLInputElement>) => {
    let validateTemporal = event.target.value;
    if (validateTemporal !== "") {
      setsubmit({ ...submit, TemporalPassword: md5(event.target.value) });
    } else {
      setsubmit({ ...submit, TemporalPassword: "" });
    }
  };

  const handleChangeNewPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPassword({ ...password, newPassword: event.target.value });
    setErrors({ ...password, confirmPassword: "" });
    let validateNew = event.target.value;
    if (validateNew !== password.confirmPassword) {
      setEnableButton(true);
      setErrors({ ...password, confirmPassword: "Password doesn't match." });
    } else {
      setEnableButton(false);
    }
  };

  const handleChangeConfirm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword({ ...password, confirmPassword: event.target.value });
    setErrors({ ...password, confirmPassword: "" });
    let validate = event.target.value;
    if (validate !== password.newPassword) {
      setEnableButton(true);
      setErrors({ ...password, confirmPassword: "Password doesn't match." });
    } else {
      setEnableButton(false);
    }
  };

  useEffect(() => {
    if (password.newPassword !== "") {
      var mdPassword = md5(password.newPassword);
      setsubmit({
        ...submit,
        Email: cookies.get("email"),
        NewPassword: mdPassword,
      });
    }
  }, [password.newPassword]);

  return (
    <div className="container">
      <Box className="d-flex flex-column align-items-center justify-content-center fm_login_container">
        <div className="fm_card fm_rp d-flex flex-column">
          <h1 className="logincard__form__title text-center">New Password</h1>
          <p className="text-center">Please, enter your new password.</p>
          <TextField
            fullWidth
            className="fm_input"
            size="small"
            label="Email Address"
            name="email"
            type="email"
            disabled={true}
            variant="outlined"
            value={cookies.get("email")}
          />
          <TextField
            fullWidth
            className="fm_input"
            size="small"
            label="Temporary Password"
            name="temporalPassword"
            type="email"
            variant="outlined"
            onChange={handleChangeTemporal}
          />
          <TextField
            fullWidth
            className="fm_input"
            size="small"
            label="New Password"
            name="newPassword"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Box
                      component={Icon}
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleChangeNewPassword}
          />
          <TextField
            fullWidth
            className="fm_input"
            size="small"
            label="Confirm Password"
            name="confirmPassword"
            type={confirmPass ? "text" : "password"}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setconfirmPass((prev) => !prev)}
                  >
                    <Box
                      component={Icon}
                      icon={confirmPass ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleChangeConfirm}
            error={Boolean(errors?.confirmPassword)}
            helperText={errors?.confirmPassword}
          />
          <Button
            fullWidth
            size="large"
            variant="contained"
            className="fm-button--colored"
            disabled={enableButton}
            color="primary"
            onClick={() => save()}
          >
            Save
          </Button>
          {alertTitle && (
            <Alert severity="error" className="fm_rp--alert">
              <AlertTitle>Please, verify that all fields are filled</AlertTitle>
            </Alert>
          )}
          {errorSave && (
            <Alert severity="error" className="fm_rp--alert">
              <AlertTitle>The temporary password is incorrect.</AlertTitle>
            </Alert>
          )}
        </div>
      </Box>
    </div>
  );
};

export default PassUpdateComponent;
